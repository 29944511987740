import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const PrivacyPolicyPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>PRIVACY POLICY</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>Effective Date: 06 June 2023</p>

            <p>
              {" "}
              Thank you for visiting our website and for your interest in our
              travel site. At Travel-Lingual.com, we value and respect your
              privacy rights. This Privacy Policy explains how we collect, use,
              disclose, and protect your personal information when you use our
              website.
            </p>

            <p>
              1. Information We Collect<br></br>1.1 Personal Information When
              you visit our website, we may collect personal information that
              you voluntarily provide to us, such as your name and email
              address. We collect this information when you subscribe to our
              newsletter or contact us through our website. Providing this
              information is optional, and you can choose not to provide it.
            </p>

            <p>
              1.2 Non-Personal Information In addition to personal information,
              we may collect non-personal information automatically when you
              visit our website. This information may include your IP address,
              browser type, operating system, referring website, pages you
              visit, and the dates and times of your visits. We collect this
              information using cookies and similar technologies. You can adjust
              your browser settings to refuse cookies, but please note that some
              features of our website may not function properly if cookies are
              disabled.
            </p>

            <p>
              2. Use of Information We use the information we collect to provide
              and improve our services, including: <br></br>- Sending you our
              newsletter if you have subscribed to it. <br></br>- Responding to
              your inquiries and providing customer support. <br></br>-
              Analyzing website usage trends and preferences to enhance user
              experience. <br></br>- Detecting and preventing fraud, security
              breaches, and other potentially harmful activities.
            </p>

            <p>
              3. Disclosure of Information We do not sell, trade, or rent your
              personal information to third parties for their marketing
              purposes. However, we may disclose your personal information in
              the following circumstances: <br></br>- With your consent: We may
              share your information with third parties if you have given us
              your explicit consent to do so. <br></br>- Service providers: We
              may engage trusted third-party service providers to perform
              certain functions on our behalf, such as hosting, data analysis,
              and customer support. These providers have access to your personal
              information only to the extent necessary to perform their
              functions and are obligated to maintain its confidentiality.{" "}
              <br></br>- Legal obligations: We may disclose your information if
              required to do so by law or in response to valid legal requests,
              such as court orders, subpoenas, or government investigations.
            </p>

            <p>
              4. Data Security We implement appropriate technical and
              organizational measures to protect your personal information
              against unauthorized access, disclosure, alteration, or
              destruction. However, please note that no method of transmission
              over the internet or electronic storage is 100% secure, and we
              cannot guarantee absolute security.
            </p>

            <p>
              5. Children's Privacy Our website is not intended for individuals
              under the age of 16. We do not knowingly collect personal
              information from children. If you are a parent or guardian and
              believe that your child has provided us with personal information
              without your consent, please contact us, and we will delete such
              information from our records.
            </p>

            <p>
              6. Third-Party Websites Our website contains links to third-party
              websites that are not operated by us. We are not responsible for
              the privacy practices or content of these websites. We encourage
              you to review the privacy policies of any third-party websites you
              visit.
            </p>

            <p>
              7. Changes to this Privacy Policy We reserve the right to modify
              this Privacy Policy at any time. Any changes will be effective
              immediately upon posting the updated Privacy Policy on our
              website. We encourage you to review this Privacy Policy
              periodically for any updates.
            </p>

            <p>
              8. Contact Us If you have any questions, concerns, or requests
              regarding this Privacy Policy or our privacy practices, please
              contact us at hello@travel-lingual.com
            </p>

            <p>
              By using our website, you consent to the terms of this Privacy
              Policy.
            </p>

            <p>Last Updated: 06 June 2023</p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default PrivacyPolicyPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Privacy Policy | Travel-Lingual"}
      description={
        "Your data, your trust. Explore our commitment to protecting your privacy and how we handle your information on Travel-Lingual.com."
      }
      pathname={location.pathname}
    />
  );
};
